/* eslint-disable no-console */
import React, { useEffect } from "react";
import { TeliaBadge } from "@telia/b2x-telia-badge";
import { TeliaCtaLink } from "@telia/b2x-telia-cta-link";

type Props = {
  linkSuffix: string;
};

export const obsoleteWarning = (name: string, linkSuffix: string) => {
  if (
    window?.location?.host?.includes("localhost") &&
    process.env.NODE_ENV !== "test" &&
    process.env.DISABLE_B2X_OBSOLETE_COMPONENT_WARNING_LOG !== "true"
  ) {
    console.warn(
      `${name} is obsolete - please use the purpur version: https://purpur.telia.io/?path=/docs/${linkSuffix}`
    );
  }
};

export const useObsoleteWarning = (name: string, linkSuffix: string) => {
  useEffect(() => {
    obsoleteWarning(name, linkSuffix);
  }, [name, linkSuffix]);
};

export const B2xObsoleteComponent = ({ linkSuffix }: Props) => (
  <div
    style={{
      backgroundColor: "var(--purpur-color-background-status-error-strong)",
      padding: "24px",
      borderRadius: "8px",
      marginBottom: "16px",
    }}
  >
    <TeliaBadge variant="error">Obsolete</TeliaBadge>
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <b>Please use the Purpur version of this component</b>
      <TeliaCtaLink
        href={`https://purpur.telia.io/?path=/docs/${linkSuffix}`}
        variant="secondary"
        size="sm"
        target="_blank"
      >
        Purpur
      </TeliaCtaLink>
    </div>
  </div>
);
